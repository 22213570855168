import { css } from '@emotion/react';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CrispService, RouteLink, Types } from '@scorenco/core';

import { Image } from '../../primitives';
import { Card, CardProps } from '../../primitives/Card';

type FeatureProps = CardProps & {
  feature: Types.ComponentFeatureListFeature;
};

const FeatureContent = ({ feature, ...rest }: FeatureProps) => {
  const hasLink = !!feature.ctaLink;

  return (
    <Card
      component={hasLink ? 'a' : 'div'}
      disabled={!hasLink}
      {...rest}
      sx={{ minWidth: '14.5rem' }}
    >
      <Box
        sx={{
          width: '6rem',
          height: '6rem',
          my: 2,
        }}
      >
        <Image
          src={feature.icon?.data?.attributes?.url}
          css={css`
            object-fit: contain;
            width: 100%;
            height: 100%;
          `}
        />
      </Box>
      <Typography
        component="p"
        css={(theme) => css`
          margin-top: ${theme.spacing(2)};
          text-align: center;
          line-height: 1em;
          margin-bottom: 0.5em;
          font-weight: bolder;
        `}
      >
        {feature.name}
      </Typography>
      <Typography
        component="p"
        css={css`
          flex: 1;
          text-align: center;
          font-size: 0.9em;
        `}
      >
        {feature.content}
      </Typography>
      {hasLink && (
        <Button
          sx={{ mt: 2 }}
          endIcon={<KeyboardArrowRight />}
          color="primary"
          variant="text"
        >
          {feature.ctaText}
        </Button>
      )}
    </Card>
  );
};

export const Feature = ({ feature, ...rest }: FeatureProps) => {
  if (feature?.ctaLink?.startsWith('#crisp')) {
    return (
      <FeatureContent
        feature={feature}
        onClick={() => {
          CrispService.show();
        }}
        {...rest}
      />
    );
  }

  return (
    <RouteLink href={feature.ctaLink}>
      <FeatureContent feature={feature} {...rest} />
    </RouteLink>
  );
};
