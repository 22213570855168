import { PropsWithChildren, forwardRef } from 'react';

import { css } from '@emotion/react';
import { Stack, StackProps } from '@mui/system';

export type CardProps = PropsWithChildren<
  StackProps & {
    disabled?: boolean;
    alignItems?: 'center' | 'baseline';
  }
>;

export const Card = forwardRef(
  (
    { disabled = false, children, alignItems = 'center', ...rest }: CardProps,
    ref: any
  ) => (
    <Stack
      css={(theme) =>
        [
          css`
            align-items: ${alignItems};
            justify-content: center;
            padding: ${theme.spacing(2)};
            color: ${theme.vars.palette.text.primary};
            text-decoration: none;
            border: 1px solid ${theme.vars.palette.divider};
            border-radius: 1rem;
          `,
          !disabled &&
            css`
              cursor: pointer;
              transition: transform 0.2s;

              &:hover {
                transform: scale(1.02);
                background-color: ${theme.vars.palette.background.paper};
                box-shadow: 0px 5px 20px 0px rgba(210, 224, 239, 0.5);
                border-color: transparent;
              }
            `,
        ].filter(Boolean)
      }
      ref={ref}
      {...rest}
    >
      {children}
    </Stack>
  )
);
