import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { Types } from '@scorenco/core';

import { Wrapper } from '../../primitives';

import { Feature } from './Feature';

type FeaturesListSliceProps = {
  slice: Types.ComponentSlicesFeaturesList;
};

export const FeaturesListSlice = ({ slice }: FeaturesListSliceProps) => {
  return (
    <Wrapper marginY={5}>
      <Box
        css={(theme) => css`
          display: flex;
          flex-flow: wrap;
          max-width: ${theme.breakpoints.values.md}px;
          justify-content: space-evenly;
          row-gap: 3rem;
          column-gap: 2rem;
          margin: 0 auto;
          margin-bottom: 3rem;
          margin-top: 3rem;
          align-items: stretch;
        `}
      >
        {slice.features?.map((feature) => {
          if (!feature) {
            return null;
          }

          return (
            <Feature
              key={feature.id}
              feature={feature}
              css={css`
                flex: 0 0 16rem;
                width: 16rem;
              `}
            />
          );
        })}
      </Box>
    </Wrapper>
  );
};
